import { useEffect, useState } from "react"

export function getRocketSale() {

  const [sale, saleData] = useState('')

  useEffect(() => {
    const fetchData = async () => {
    try {
    fetch(`https://api.mezzoguild.com/sales/rocket.json`)
      .then(response => response.json())
      .then(resultData => {
        saleData(resultData)
      })}
      catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])

  return sale
}