import React from "react"

export const English = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={props.height}
      x="0"
      y="0"
      viewBox="0 0 48 48"
    >
      <path
        fill="#002781"
        d="M46 6H2a2 2 0 00-2 2v32a2 2 0 002 2h44a2 2 0 002-2V8a2 2 0 00-2-2z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M48 8a2 2 0 00-2-2h-5.161L28 15.876V6h-8v9.876L7.161 6H2a2 2 0 00-2 2v2.586L12.239 20H0v8h12.239L0 37.415V40a2 2 0 002 2h5.161L20 32.124V42h8v-9.876L40.839 42H46a2 2 0 002-2v-2.585L35.761 28H48v-8H35.761L48 10.586z"
      ></path>
      <path
        fill="#d10d24"
        d="M48 22L26 22 26 6 22 6 22 22 0 22 0 26 22 26 22 42 26 42 26 26 48 26 48 22z"
      ></path>
      <path
        fill="#d10d24"
        d="M20 27.723L1.582 41.955A2.051 2.051 0 002 42h2.386L20 29.935zM28 20.277L46.418 6.045A2.058 2.058 0 0046 6h-2.386L28 18.066zM18.006 20L.615 6.562A1.989 1.989 0 000 8v.3L15.144 20zM29.994 28l17.391 13.438A1.989 1.989 0 0048 40v-.3L32.856 28z"
      ></path>
    </svg>
  )
}
