import React from "react"
import { StaticQuery, graphql, navigate } from "gatsby"
import GitHubButton from "react-github-btn"
import Link from "./link"
import "./styles.css"
import config from "../../config.js"

import { getProfile, isAuthenticated, logout } from "../utils/auth"

import Loadable from "react-loadable"
import LoadingProvider from "./mdxComponents/loading"

const help = require("./images/help.svg")
const isSearchEnabled =
  config.header.search && config.header.search.enabled ? true : false

let searchIndices = []
if (isSearchEnabled && config.header.search.indexName) {
  searchIndices.push({
    name: `${config.header.search.indexName}`,
    title: `Results`,
    hitComp: `PageHit`
  })
}

import Sidebar from "./sidebar"

const LoadableComponent = Loadable({
  loader: () => import("./search/index"),
  loading: LoadingProvider
})

const handleLogin = () => {
  navigate("/members")
}

function toggleNavBar() {
  var x = document.getElementById("navbar")
  if (x.className === "topnav") {
    x.className += " responsive"
  } else {
    x.className = "topnav"
  }
}

const Header = ({ location, lang }) => (
  <StaticQuery
    query={graphql`
      query headerTitleQuery {
        site {
          siteMetadata {
            headerTitle
            githubUrl
            helpUrl
            tweetText
            logo {
              link
              image
            }
            headerLinks {
              link
              text
            }
          }
        }
      }
    `}
    render={(data) => {
      const logoImg = require("./images/logo.svg")
      const twitter = require("./images/twitter.svg")
      const {
        site: {
          siteMetadata: {
            headerTitle,
            githubUrl,
            helpUrl,
            tweetText,
            logo,
            headerLinks
          }
        }
      } = data
      const finalLogoLink = logo.link !== "" ? logo.link : "/"
      const isEnglish = lang === "en"
      const isGerman = lang === "de"
      const isSpanish = lang === "es"
      const isItalian = lang === "it"
      const isPortuguese = lang === "por"

      const user = getProfile()

      return (
        <div className={"navBarWrapper"}>
          <div className={"navBarBorder"} />
          <nav className={"navBarDefault"}>
            <div className={"navBarHeader"}>
              <Link
                to={isEnglish ? "/" : "/" + lang + "/"}
                className={"navBarBrand"}
              >
                <img
                  className="img-responsive displayInline"
                  src="/logo.svg"
                  alt="Free Japanese Lessons"
                />
              </Link>
              {/* <div
                className={'headerTitle displayInline'}
                dangerouslySetInnerHTML={{__html: headerTitle}}
              /> */}
              <span onClick={toggleNavBar} className={"navBarToggle"}>
                <span className={"iconBar"}></span>
                <span className={"iconBar"}></span>
                <span className={"iconBar"}></span>
              </span>
            </div>

            {isSearchEnabled ? (
              <div className={"searchWrapper hiddenMobile navBarUL"}>
                <LoadableComponent collapse={true} indices={searchIndices} />
              </div>
            ) : null}

            <div id="navbar" className={"topnav"}>
              <div className={"visibleMobile"}>
                <Sidebar location={location} lang={lang} />
                <hr />
                {isSearchEnabled ? (
                  <div className={"searchWrapper"}>
                    <LoadableComponent
                      collapse={true}
                      indices={searchIndices}
                    />
                  </div>
                ) : null}
              </div>
              <ul className={"navBarUL navBarNav navBarULRight"}>
                {!isAuthenticated() ? null : (
                  <li className="navLink">
                    <Link to="/members/profile" style={{ cursor: `pointer` }}>
                      {user.name}
                    </Link>
                  </li>
                )}

                {isEnglish ? (
                  <li className="navButton">
                    <Link to="/members" style={{ cursor: `pointer` }}>
                      Members
                    </Link>
                  </li>
                ) : isGerman ? (
                  <li className="navButton">
                    <Link to="/members" style={{ cursor: `pointer` }}>
                      Mitglieder
                    </Link>
                  </li>
                ) : isSpanish ? (
                  <li className="navButton">
                    <Link to="/members" style={{ cursor: `pointer` }}>
                      Miembros
                    </Link>
                  </li>
                ) : isItalian ? (
                  <li className="navButton">
                    <Link to="/members" style={{ cursor: `pointer` }}>
                      Membri
                    </Link>
                  </li>
                ) : (
                  <li className="navButton">
                    <Link to="/members" style={{ cursor: `pointer` }}>
                      Membros
                    </Link>
                  </li>
                )}

                {!isAuthenticated() ? null : (
                  <li className="navButton">
                    <a
                      href="#logout"
                      style={{ cursor: `pointer` }}
                      onClick={(e) => {
                        logout()
                        e.preventDefault()
                      }}
                    >
                      Logout
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </nav>
        </div>
      )
    }}
  />
)

export default Header
